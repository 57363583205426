<template>
  <ion-page id="page-content">
    <ion-modal
      @didDismiss="showRegisterTip = false"
      :is-open="showRegisterTip"
      css-class="tcc-modal"
      :animated="false"
    >
      <div class="model-box">
        <div class="ion-page">
          <div class="modal-content friend-modal">
            <p class="font-18 font-b main-color" v-if="loginType == 1">
              {{ $t("register.Youremailaddressis") }}<br />
              {{ $t("register.alreadyregistered") }}
            </p>
            <p class="font-18 font-b main-color" v-if="loginType == 2">
              {{ $t("register.Yourmobilenumberis") }} <br />
              {{ $t("register.alreadyregistered") }}
            </p>
            <p class="font-18 font-b main-color" style="margin-top: 30px">
              {{ $t("register.Gotologin") }}
            </p>
            <div class="btn-box" style="display: flex">
              <button class="btn" @click="showRegisterTip = false">
                {{ $t("general.No") }}
              </button>
              <button class="btn" @click="goLogin">{{ $t("general.Yes") }}</button>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true">
      <topbar
        :title="$t('register.Registration')"
        :showBack="true"
        :noboder="true"
      ></topbar>
      <div class="pc-box">
        <div class="pc-min-content">
          <img
            v-if="!$store.state.isPC"
            src="../assets/login.jpg"
            style="width: 100%"
            class="login-page-img"
          />
          <div class="content login-page">
            <div class="title-PC" v-if="$store.state.isPC">
              {{ $t("register.Registration") }}
            </div>
            <div v-if="loginType == 2">
              <div class="form-item p-b-20">
                <label>{{ $t("login.Mobilenumber") }}</label>
                <!-- <div class="mobile-number item-table"> -->
                <!-- <div class="tdcell code">
                    <span class="Medium">+61</span><i class="icon-arrowLeft-2"></i>
                  </div> -->
                <div class="input-box">
                  <ion-input
                    class="Helveticaneue"
                    type="tel"
                    maxlength="10"
                    :value="$store.state.userInfo.phone"
                    :placeholder="$t('login.Mobilenumber')"
                    @ionBlur="checkFormat('phone')"
                    @ionInput="
                      $store.state.userInfo.phone = $event.target.value.trim();
                      errorFormat = false;
                    "
                  />
                </div>
                <!-- </div> -->
                <p v-if="errorFormat" class="tip red">
                  {{ $t("login.MobileFormatError") }}
                </p>
              </div>
              <div class="form-item p-b-0">
                <label>{{ $t("register.ConfirmMobileNumber") }}</label>
                <!-- <div class="mobile-number item-table"> -->
                <!-- <div class="tdcell code">
                    <span class="Medium">+61</span><i class="icon-arrowLeft-2"></i>
                  </div> -->
                <div class="input-box">
                  <ion-input
                    class="Helveticaneue"
                    type="tel"
                    maxlength="10"
                    :value="confirmPhone"
                    :placeholder="$t('login.Mobilenumber')"
                    @ionBlur="checkConfirm('phone')"
                    @ionInput="
                      confirmPhone = $event.target.value.trim();
                      confirmInput($event, 'phone');
                    "
                  />
                </div>
                <!-- </div> -->
                <p class="tip red" v-if="errorConfirm">
                  {{ $t("register.Mobiledonotmatch") }}
                </p>
              </div>
            </div>

            <div v-if="loginType == 1">
              <div class="form-item p-b-20">
                <label>{{ $t("login.Email") }}</label>
                <div class="input-box">
                  <ion-input
                    class="Helveticaneue"
                    type="text"
                    :value="$store.state.userInfo.email"
                    :placeholder="
                      $store.state.isPC
                        ? 'e.g. thomas.ritchies@ritchies.com.au'
                        : 'Email Address'
                    "
                    @ionBlur="checkFormat('email')"
                    @ionInput="
                      $store.state.userInfo.email = $event.target.value
                        .trim()
                        .toLowerCase();
                      errorFormat = false;
                    "
                  />
                </div>
                <p v-if="errorFormat" class="tip red">
                  {{ $t("login.EmailFormatError") }}
                </p>
              </div>
              <div class="form-item p-b-0">
                <label>{{ $t("register.ConfirmEmail") }}</label>
                <div class="input-box">
                  <ion-input
                    class="Helveticaneue"
                    type="text"
                    :value="confirmEmail"
                    :placeholder="
                      $store.state.isPC
                        ? 'e.g. thomas.ritchies@ritchies.com.au'
                        : 'Email Address'
                    "
                    @ionBlur="checkConfirm('email')"
                    @ionInput="
                      confirmEmail = $event.target.value.trim().toLowerCase();
                      confirmInput($event, 'email');
                    "
                  />
                </div>
                <p class="tip red" v-if="errorConfirm">
                  {{ $t("register.Emaildonotmatch") }}
                </p>
              </div>
            </div>
            <div class="continueWithEmail">
              <p v-if="loginType == 1">
                {{ $t("register.TRPE") }}
              </p>
              <p v-if="loginType == 2">{{ $t("register.TRPM") }}</p>
            </div>
            <ion-button
              :disabled="
                (loginType == 2 && !($store.state.userInfo.phone && isConfirm)) ||
                (loginType == 1 && !($store.state.userInfo.email && isConfirm))
              "
              expand="block"
              class="Bold"
              @click="checkEvent"
              >{{ $t("login.Continue") }}</ion-button
            >
            <div class="or-hr"><span>or</span></div>
            <div class="btn-plain" v-if="loginType == 2" @click="loginTypeChange(1)">
              <i class="icon-mail"></i>
              <span class="Medium">{{ $t("login.ContinuewithEmail") }}</span>
            </div>
            <div class="btn-plain" v-if="loginType == 1" @click="loginTypeChange(2)">
              <i class="icon-mobile"></i>
              <span class="Medium">{{ $t("login.ContinuewithMobile") }}</span>
            </div>
            <!-- <div v-if="loginType==2" class="continueWithEmail Medium">For existing member, please continue with email. </div> -->
            <!-- <div v-if="loginType==1" class="continueWithEmail Medium">For existing member, please continue with mobile.</div> -->
            <div class="downloadTip Medium" v-if="!$store.state.appVersion">
              {{ $t("register.ODAPP") }}
            </div>
            <div class="downloadBtn" v-if="!$store.state.appVersion">
              <img src="../assets/appstore.png" @click="jumpDownload(1)" alt="appstore" />
              <img
                src="../assets/googleplay.png"
                @click="jumpDownload(2)"
                alt="googleplay"
              />
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton, IonModal, IonInput } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "registrationPage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonButton, IonModal, IonInput },
  data() {
    return {
      loginType: 1,
      ispassword: true,
      phone: "",
      confirmPhone: "",
      password: "",
      phoneAreaCode: "+61",
      email: "",
      confirmEmail: "",
      rightTitle: "",
      showRegisterTip: false,
      errorConfirm: false,
      isConfirm: false,
      errorFormat: false,
      clickFlag: true,
      canSendS: true,
      canSendE: true,
    };
  },
  methods: {
    goLogin() {
      this.showRegisterTip = false;
      this.$router.push({
        path: "/login",
      });
      this.sendGAEvent("Click", "Registration", "Go Log in");
    },
    // facebookLoginEvent() {
    //   this.facebookLogin(this.fbLogin);
    //   this.sendGAEvent("Click", "Registration", "Facebook");
    // },
    async fbLogin(obj) {
      const objdata = this.getAuthObj();
      objdata.snsId = obj.userId;
      objdata.snsType = 1;
      objdata.snsAccessToken = obj.token;
      objdata.loginType = 3;
      if (this.$store.state.appVersion) {
        objdata.loginSource = 1; //app
      } else {
        objdata.loginSource = 2; //web
      }
      objdata.apiUrl = this.apiConfig.login;
      const data = await this.$store.dispatch("apiEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$store.state.form.token = data.data.result.token;
      this.getUserPofileEvent();
    },

    async checkEvent() {
      if (!this.clickFlag) {
        return;
      }
      this.clickFlag = false;
      const objdata = this.getAuthObj();
      if (this.loginType == 2) {
        this.sendGAEvent("Click", "Registration", "Phone");
        objdata.phone = this.$store.state.userInfo.phone;
        objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      } else if (this.loginType == 1) {
        this.sendGAEvent("Click", "Registration", "Email");
        objdata.email = this.$store.state.userInfo.email.trim().toLowerCase();
      }
      objdata.type = this.loginType;
      objdata.apiUrl = this.apiConfig.checkIsExistAccount;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      this.clickFlag = true;
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (data.data.result.existAccount) {
        this.showRegisterTip = true;
      } else {
        this.confirmEvent();
      }
    },
    async sendSmsEvent() {
      if (!this.canSendS) return;
      this.canSendS = false;
      const objdata = this.getAuthObj();
      objdata.phone = this.$store.state.userInfo.phone;
      objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      objdata.apiUrl = this.apiConfig.sendSms;
      objdata.verifyType = 1;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendS = true;
      }, 1500);
      if (!data) {
        return;
      }

      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$store.state.userInfo.registerType = 2;
      this.$router.push({
        path: "/verifyCode/1/2",
      });
    },
    async sendEmailEvent() {
      if (!this.canSendE) return;
      this.canSendE = false;
      const objdata = this.getAuthObj();
      objdata.email = this.$store.state.userInfo.email.trim().toLowerCase();
      objdata.apiUrl = this.apiConfig.sendVerifyEmail;
      objdata.verifyType = 1;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendE = true;
      }, 1500);
      if (!data) {
        return;
      }

      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$store.state.userInfo.registerType = 1;
      this.$router.push({
        path: "/verifyCode/1/1",
      });
    },
    confirmEvent() {
      if (this.loginType == 2) {
        this.sendSmsEvent();
      } else {
        this.sendEmailEvent();
      }
    },
    jumpDownload() {
      window.open(process.env.VUE_APP_SHARE_PAGE_URL + "index.html");
      // type = type == 1 ? "appstore" : "googleplay"
      //   window.location.href = "https://cdn-tccrichies-uat.azureedge.net/share/index.html";
    },
    loginTypeChange(type) {
      this.loginType = type;
      this.$store.state.userInfo.email = "";
      this.confirmEmail = "";
      this.$store.state.userInfo.phone = "";
      this.confirmPhone = "";
      this.errorConfirm = false;
      this.errorFormat = false;
    },
    checkConfirm(type) {
      let confirmType = type == "phone" ? "Phone" : "Email";
      if (
        this.$store.state.userInfo[type].toLowerCase() ==
        this[`confirm${confirmType}`].toLowerCase()
      ) {
        this.errorConfirm = false;
        if (!this.errorFormat) {
          this.isConfirm = true;
        } else {
          this.isConfirm = false;
        }
      } else {
        this.isConfirm = false;
        this.errorConfirm = true;
      }
    },
    confirmInput(e, type) {
      this.errorConfirm = false;
      if (e.target.value.length >= this.$store.state.userInfo[type].length) {
        this.checkConfirm(type);
      }
    },
    checkFormat(type) {
      let confirmType = type == "phone" ? "Phone" : "Email";
      if (!this.$store.state[`${type}Patrn`].test(this.$store.state.userInfo[type])) {
        this.errorFormat = true;
        this.isConfirm = false;
      }
      if (this[`confirm${confirmType}`]) {
        this.checkConfirm(type);
      }
    },
  },
  ionViewWillLeave() {
    if (this.loginType == 2) {
      // phone
      this.$store.state.userInfo.email = "";
      this.confirmEmail = "";
    } else if (this.loginType == 1) {
      // email
      this.$store.state.userInfo.phone = "";
      this.confirmPhone = "";
      if (
        this.$store.state.verifyUserInfo &&
        this.$store.state.verifyUserInfo.email &&
        this.$store.state.verifyUserInfo.email.trim().toLowerCase() !=
          this.$store.state.userInfo.email.trim().toLowerCase()
      ) {
        this.$store.state.verifyUserInfo = null;
      }
    }
    this.$forceUpdate();
  },
  ionViewWillEnter() {
    this.$store.state.needVerify = false;
    this.loginType = 1;
    this.ispassword = true;
    this.$store.state.userInfo.password = "";
    this.phone = "";
    this.password = "";
    this.phoneAreaCode = "+61";
    this.emailVerifyCode = "";
    this.$store.state.userInfo.phoneAreaCode = "+61";
    this.$store.state.userInfo.firstName = "";
    this.$store.state.userInfo.lastName = "";
    this.email = "";
    this.rightTitle = "";
    if (this.$store.state.firstPage == 1) {
      this.rightTitle = this.$t("me.Login");
    }
    this.errorConfirm = false;
    this.isConfirm = false;
    this.confirmEmail = "";
    this.confirmPhone = "";
    this.$store.state.exitApp = false;
    this.sendGAPageView("Signup");
  },
};
</script>
